$cdnURL: "https://rest-filestore.whiteclover.uk/files/whiteclover.io";
.yourStory {
  margin-top: 80px;

  position: relative;
  text-align: center;

  min-height: 750px;
  height: 100vh;

  img {
    width: 100%;
  }
  .textBlock {
    width: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;

  }
  h1 {
    color: #63435B;
  }

}

.carousel {

  .backgroundImg {
    position: relative;
    background-image: url($cdnURL + "/newassets/story_couples_photo.jpg");
    background-size: contain;
    background-repeat: no-repeat;

    width: 100%;
    min-height: 750px;
    height: 100vh;

  }

  .textBlock {
    width: 100%;

    min-height: 750px;
    height: 100vh;

    position: relative;
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);

    text-align: center;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    gap: 24px;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    h2,p {
      color: #63435B;
    }


  }

}

.maskedImg {
  width: 200px;
  height: 200px;

  -webkit-mask-image: url($cdnURL + "/newassets/ellipse_Vector.svg")
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.letStart {

  position: relative;
  text-align: center;

  img {
    filter: blur(0px);
    width: 100vw;
    height: 100vh;
    min-height: 750px;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: 720px) {
      width: 100%;
    }
  }

  .textBlock {
    width: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;

    h2 {
      width: 70%;
      margin: 0 auto;
      color: #F4F3F4;
      margin-bottom: 24px ;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 32px;
      gap: 10px;

      margin: 0 auto;

      width: 249px;
      height: 40px;

      border: none;

      /* Brand Color/Orange 2 */
      background: #F9AC80;
      border-radius: 8px;
      color: #63435B;
      cursor: pointer;

      &:hover {
          background: #63435B;
          color: #F4F3F4 !important;
      }

      @media screen and (min-width: 1921px) {
        width: 12.969vw; //249px;
        height: 2.083vw; //40px;
      }
    }

  }

}
